import React from "react"
import Img from "gatsby-image"
import { getFluidGatsbyImage } from "gatsby-source-sanity"
import styled from "styled-components"
import BlockContent from "../block-content"

const Container = styled.div`
  display: grid;
  border: 5px solid ${props => props.inputColor || "#9bc2e5"};
  box-shadow: 30px 30px 0px ${props => props.inputColor || "#9bc2e5"};
  margin-right: 30px;
  grid-template-columns: 2fr 5fr;
  grid-column-gap: 20px;
  @media (max-width: 675px) {
    grid-template-columns: 1fr 1fr;
    margin-bottom: 40px;
  }
`
const LeftTop = styled.div`
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  @media (max-width: 675px) {
    grid-column: 2 / span 1;
    grid-row: 1 / span 1;
  }
`
const LeftBottom = styled.div`
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
  align-self: flex-end;
  @media (max-width: 675px) {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    align-self: flex-start;
  }
`
const Right = styled.div`
  grid-column: 2 / span 1;
  grid-row: 1 / span 2;
  @media (max-width: 675px) {
    grid-column: 1 / span 2;
    grid-row: 2 / span 1;
  }
`
const AuthorImage = styled(Img)``
const AuthorName = styled.h2`
  margin-bottom: 10px;
  margin-top: 10px;
  padding-left: 5px;
`
const AuthorTitle = styled.p`
  padding-left: 5px;
  margin: 0;
`

function AuthorBox({ author, color }) {
  const sanityConfig = { projectId: "cyo8ldkg", dataset: "staging" }
  const imageAssetId = author.image.asset._id
  const fluidProps = getFluidGatsbyImage(imageAssetId, { maxWidth: 200 }, sanityConfig)
  return (
    <Container className="mainContent" inputColor={color}>
      <LeftTop>
        <AuthorName>{author.name}</AuthorName>
        <AuthorTitle>{author.title}</AuthorTitle>
      </LeftTop>
      <LeftBottom>
        <AuthorImage fluid={fluidProps} />
      </LeftBottom>
      <Right>
        <BlockContent blocks={author.bio} />
        <a href={`mailto: ${author.email}`}>{author.email}</a>
      </Right>
    </Container>
  )
}

export default AuthorBox
