import React, { useEffect } from "react"
import styled from "styled-components"

const Container = styled.div`
  margin-top: 5em;
`

const CONTAINER_ID = "commento"
const SCRIPT_ID = "commento-script"
const COMMENTO_URL = "https://cdn.commento.io/js/commento.js"

const insertScript = (src, id, parentElement, dataAttributes) => {
  const script = window.document.createElement("script")
  script.async = true
  script.src = src
  script.id = id

  Object.entries(dataAttributes).forEach(([key, value]) => {
    if (value === undefined) {
      return
    }
    script.setAttribute(`data-${key}`, value.toString())
  })

  parentElement.appendChild(script)
}

const removeScript = (id, parentElement) => {
  const script = window.document.getElementById(id)
  if (script) {
    parentElement.removeChild(script)
  }
}

const Commento = props => {
  const { id, cssOverride, autoInit, noFonts, hideDeleted, pageId } = props
  useEffect(() => {
    if (!window) {
      return
    }
    const document = window.document
    if (document.getElementById("commento")) {
      insertScript(COMMENTO_URL, SCRIPT_ID, document.body, {
        "css-override": cssOverride,
        "auto-init": autoInit,
        "no-fonts": noFonts,
        "hide-deleted": hideDeleted,
        "page-id": pageId
      })
    }
    return () => removeScript(SCRIPT_ID, document.body)
  }, [autoInit, cssOverride, hideDeleted, id, noFonts, pageId])

  return <Container id={CONTAINER_ID} />
}
export default Commento
