import React, { useState } from "react"
import Img from "gatsby-image"
import { getFluidGatsbyImage } from "gatsby-source-sanity"
import { Link } from "gatsby"
import Carousel from "nuka-carousel"
import sanityConfigAll from "../../../../studio/sanity.json"
import { getBlogUrl, mapEdgesToNodes, sortData } from "../../lib/helpers"
import RightArrow from "../../assets/right-arrow.svg"
import styles from "../../styles/related-articles.module.css"

function RelatedArticles({ siblings, currentId }) {
  const [slideIndex, setSlideIndex] = useState(0)
  const changeSlideIndex = index => setSlideIndex(index)

  if (siblings.edges.length === 0) return null

  const otherArticles = mapEdgesToNodes(siblings)
    .filter(posts => posts.id !== currentId && posts.publishedAt !== null)
    .sort(sortData("-publishedAt"))
    .slice(0, 6)

  const singleArticle = element => {
    const fluidProps = getFluidGatsbyImage(
      element.mainImage.asset._id,
      { maxHeight: 600, maxWidth: 600 },
      sanityConfigAll.api
    )
    return (
      <Link
        key={element.title}
        style={{ textDecoration: "none" }}
        to={`/${getBlogUrl(
          element.parenttag.slug.current,
          element.slug.current,
          element.articleTier
        )}`}
      >
        <div className={styles.singleArticle}>
          <div className={styles.relatedArtImgDiv}>
            <Img fluid={fluidProps} alt={element.mainImage.alt} />
          </div>
          <div className={styles.SecRelatedArtDiv}>
            <div className={styles.titleAndAuthor}>
              <div
                key={element.category.title}
                style={{
                  backgroundColor: element.category.favoriteColor.hex,
                  color: "#19232d",
                  padding: "0 10px",
                  borderRadius: "5px",
                  position: "absolute"
                }}
              >
                {element.category.title}
              </div>

              <div key={element.title} className={styles.singleArticleTitle}>
                {element.title}
              </div>
            </div>

            <div key={element.authors.name} className={styles.singleAuthor}>
              by <span className={styles.singleAuthorSpan}>{element.authors.name}</span>
            </div>
          </div>
        </div>
      </Link>
    )
  }

  const RightArrowControl = ({ right, current, changeSlide }) => {
    return (
      <div className={styles.rightArrowControl}>
        <button className={styles.buttonStyles} onClick={() => changeSlide(current)}>
          <img src={right} alt="right-arrow-image" className={styles.arrowImage} />
        </button>
      </div>
    )
  }

  const SliderComp = ({
    widthValue = "380px",
    heightValue = "154px",
    noOfSlide = 1,
    framePaddingValue = "7px",
    slideIndexValue = slideIndex
  }) => {
    return (
      <div className={styles.itemsDiv}>
        <Carousel
          height={heightValue}
          width={widthValue}
          framePadding={framePaddingValue}
          slidesToShow={noOfSlide}
          withoutControls={false}
          transitionMode={"scroll"}
          cellAlign={"right"}
          slidesToScroll={"auto"}
          wrapAround
          slideIndex={slideIndexValue}
          heightMode={"max"}
          renderBottomCenterControls={() => null}
          renderCenterLeftControls={() => null}
          renderCenterRightControls={({ nextSlide }) => (
            <RightArrowControl
              right={RightArrow}
              current={nextSlide}
              changeSlide={changeSlideIndex}
            />
          )}
        >
          {otherArticles.map((art, index) => (
            <div key={index}>{singleArticle(art)}</div>
          ))}
        </Carousel>
      </div>
    )
  }

  const desktopView = (
    <div className={styles.allRelatedArt}>
      <div className={styles.relatedArt}>Related Articles</div>
      <SliderComp
        widthValue={"100%"}
        noOfSlide={2}
        slideIndexValue={1}
        framePaddingValue={"20px"}
      />
    </div>
  )

  const mobileView = (
    <div className={styles.allRelatedArt}>
      <div className={styles.relatedArt}>Related Articles</div>
      <SliderComp />
    </div>
  )

  if (otherArticles.length >= 3) {
    return (
      <div>
        <div className={styles.allDesktop}>{desktopView}</div>
        <div className={styles.allMobile}>{mobileView}</div>
      </div>
    )
  } else {
    return null
  }
}

export default RelatedArticles
