import React from "react"
import { graphql } from "gatsby"
import BlogPost from "../components/main/blog-post"
import Container from "../components/main/container"
import GraphQLErrorList from "../components/main/graphql-error-list"
import Layout from "../containers/layout"

export const query = graphql`
  query BlogPostTemplateQuery($id: String!, $parentid: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      publishedAt
      seoTitle
      seoSummary
      category {
        title
        catSlug
        favoriteColor {
          hex
        }
      }
      parenttag {
        slug {
          current
        }
      }
      articleTier
      _rawExcerpt
      mainImage {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
          fluid {
            ...GatsbySanityImageFluid
          }
        }
        alt
        attribution
        description
        caption
        objectFit
      }
      title
      slug {
        current
      }
      quellen {
        articlename
        author
        link
        medium
      }
      _rawAuthors(resolveReferences: { maxDepth: 3 })
      _rawBody(resolveReferences: { maxDepth: 6 })
      authors {
        _key
        image {
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          asset {
            _id
          }
        }
        name
      }
    }
    siblings: allSanityPost(filter: { parenttag: { _id: { eq: $parentid } } }) {
      edges {
        node {
          id
          publishedAt
          title
          slug {
            current
          }
          articleTier
          parenttag {
            slug {
              current
            }
          }
          category {
            title
            catSlug
            favoriteColor {
              hex
            }
          }
          mainImage {
            asset {
              _id
              fluid {
                ...GatsbySanityImageFluid
              }
            }
            alt
            attribution
            description
          }
          authors {
            _key
            name
          }
        }
      }
    }
  }
`

const BlogPostTemplate = props => {
  const { data, errors } = props
  const post = data && data.post
  const siblings = data && data.siblings
  const postData = {
    ...post,
    siblings
  }

  return (
    <Layout>
      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {postData && <BlogPost {...postData} />}
    </Layout>
  )
}

export default BlogPostTemplate
