import { Helmet } from "react-helmet"
import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { getFluidGatsbyImage } from "gatsby-source-sanity"
import slugify from "slugify"
import { format } from "date-fns"
import AuthorBox from "./author-box"
import BlockContent from "../block-content"
import Commento from "./comment"
import { buildImageObj, getBlogUrl } from "../../lib/helpers"
import { imageUrlFor } from "../../lib/image-url"
import RelatedArticles from "./related-articles"
import styles from "../../styles/blog-post.module.css"

function BlogPost(props) {
  const {
    _rawBody,
    _rawAuthors,
    id,
    category,
    title,
    parenttag,
    articleTier,
    slug,
    mainImage,
    publishedAt,
    _rawExcerpt,
    seoTitle,
    seoSummary,
    siblings
  } = props
  var categoryStyle = {
    backgroundColor: category.favoriteColor.hex
  }
  var categoryBorderStyle = {
    color: category.favoriteColor.hex
  }
  const sanityConfig = { projectId: "cyo8ldkg", dataset: "staging" }
  const imageAssetId = mainImage.asset._id
  const fluidProps = getFluidGatsbyImage(imageAssetId, { maxHeight: 400 }, sanityConfig)
  return (
    <div className={styles.articleContainer + " " + "articleContainer"}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={seoSummary} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="author" content={_rawAuthors.name} />
        <meta name="date" content={format(new Date(publishedAt), "DD.MM.YYYY")} />
        <title>{seoTitle}</title>
        <html lang="de" />
        <link
          rel="canonical"
          href={
            "https://besserschlafen.de/" +
            getBlogUrl(parenttag.slug.current, slug.current, articleTier)
          }
        />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoSummary} />
        <meta
          property="og:url"
          content={
            "https://besserschlafen.de/" +
            getBlogUrl(parenttag.slug.current, slug.current, articleTier)
          }
        />
        <meta property="og:image" content={mainImage.asset.fluid.src} />
        <meta property="og: site_name" content="besserschlafen.de" />
      </Helmet>
      <div className={styles.articleLayout + " " + "articleLayout"}>
        <div className={styles.headline + " " + "headline"}>
          <Link
            to={"/themengebiet/" + category.catSlug}
            className={styles.categoryname}
            style={categoryStyle}
          >
            {category.title}
          </Link>

          <h1 className={styles.title}>{title}</h1>
        </div>

        {mainImage && mainImage.asset && (
          <figure className={styles.mainImage + " " + " mainImage"}>
            {mainImage.hotspot !== null || mainImage.crop !== null ? (
              <img src={imageUrlFor(mainImage).url()} />
            ) : (
              <Img
                fluid={fluidProps}
                alt={mainImage.alt}
                imgStyle={{ objectFit: mainImage.objectFit || "cover" }}
              />
            )}
            <figcaption className={styles.mainImageCaption}>
              {mainImage.description} {mainImage.attribution}
            </figcaption>
          </figure>
        )}
        <div className="mainContent">
          <div className={styles.excerpt + " " + "postContentLayout"}>
            <div className={styles.authordiv}>
              <div className={styles.authorimage}>
                <img
                  src={imageUrlFor(buildImageObj(_rawAuthors.image))
                    .width(50)
                    .height(50)
                    .fit("crop")
                    .url()}
                  alt={_rawAuthors.name}
                />
              </div>
              <div className={styles.authortext}>
                <p>
                  Veröffentlicht von {_rawAuthors.name} am{" "}
                  {format(new Date(publishedAt), "DD.MM.YYYY")}
                </p>
              </div>
            </div>

            {_rawExcerpt && <BlockContent blocks={_rawExcerpt} className="articleLayout" />}
          </div>
        </div>
        <div className="mainContent">
          {/* <RelatedArticles siblings={edges} currentid={id} /> */}
          <div className={styles.zigzag} />
          <div className={styles.numbered}>
            <ol>{generateToC(_rawBody)}</ol>
          </div>

          <div className={styles.zigzag} />
        </div>

        <div className="postContentLayout">
          {_rawBody && (
            <BlockContent
              blocks={_rawBody}
              className="articleLayout"
              category={categoryStyle}
              bordercolor={categoryBorderStyle}
            />
          )}
        </div>

        {props.quellen.length > 0 && (
          <div className="mainContent">
            <h2>Quellenverzeichnis</h2>
            <div>{generateQuellen(props.quellen)}</div>
          </div>
        )}
        <AuthorBox author={_rawAuthors} color={category.favoriteColor.hex} />

        <div className="postContentLayout">
          <Commento />
        </div>

        <div className="mainContent">
          {siblings && <RelatedArticles currentId={id} siblings={siblings} />}
        </div>
      </div>
    </div>
  )
}

export default BlogPost

const arrToTree = arr => {
  const builtTree = []

  for (let i = 0; i < arr.length; i++) {
    const node = { ...arr[i], children: [], parent: {} }

    if (i === 0) builtTree.push(node)
    else if (node.level > builtTree[builtTree.length - 1].level)
      builtTree[builtTree.length - 1].children.push(node)
    else if (node.level === builtTree[builtTree.length - 1].level) builtTree.push(node)
  }

  return builtTree
}

function generateToC(portableText) {
  if (!portableText) {
    return []
  }
  const defaultHeadings = /h[2-3]/
  const headings = portableText
    .filter(({ style = "" }) => style.match(defaultHeadings))
    .map(item => {
      return {
        level: parseInt(item.style.substr(1)),
        style: item.style,
        _key: item._key,
        children: item.children,
        text: item.children[0].text
      }
    })

  const tree = arrToTree(headings)

  return renderTree(tree)
}

const generateSlug = heading =>
  slugify(heading, {
    lower: true,
    replacement: "-",
    remove: /[*+~.()'"!:@]/g
  })

function generateQuellen(rb) {
  return (
    <ul>
      {rb.map((object, i) => (
        <li key={i}>
          <a
            className={styles.sourcelink}
            rel="nofollow noopener noreferrer"
            target="_blank"
            href={object.link}
          >
            {object.articlename}
          </a>{" "}
          <span> — {object.author}</span>
        </li>
      ))}
    </ul>
  )
}

function renderTree(tree) {
  return (
    <>
      {tree.map(item => {
        const slug = generateSlug(item.text)

        return (
          <li key={item.text}>
            <a href={`#${slug}`}>{item.text}</a>
            {item.children.length !== 0 && <ol>{renderTree(item.children)}</ol>}
          </li>
        )
      })}
    </>
  )
}
